// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table{
    margin: 0 auto;
    width: 80%;
    margin-bottom: 15px;
}

.table h3{
    margin-bottom: 8px;
}

table{
    margin: 0 auto;
    color: #857559;
    font-weight: 500;
}

table th{
    text-align: start;
    width: 50%;
}

table, th, td{
    border: #857559 solid 1px;
}

th,td{
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PricelistPage/PricelistPage.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".table{\n    margin: 0 auto;\n    width: 80%;\n    margin-bottom: 15px;\n}\n\n.table h3{\n    margin-bottom: 8px;\n}\n\ntable{\n    margin: 0 auto;\n    color: #857559;\n    font-weight: 500;\n}\n\ntable th{\n    text-align: start;\n    width: 50%;\n}\n\ntable, th, td{\n    border: #857559 solid 1px;\n}\n\nth,td{\n    padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
