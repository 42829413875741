import React from "react";
import './MassageContainer.css'

function MassageContainer(props) {

  return (
    <div className="massage-card">
      <p>{props.el.name}</p>
      <a href={props.el.desc} download={"masaż.pdf"}>Więcej...</a>
    </div>
  );
}

export default MassageContainer;
