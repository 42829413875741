import React from "react";
import Header from "../../components/containers/header/Header";
import MassageContainer from "../../components/containers/MassageContainer/MassageContainer";
import "./MainPage.css";
import Phone from "../../components/elements/Phone";
import Address from "../../components/elements/Address";
import Facebook from "../../components/elements/Facebook";

function MainPage() {
  const MASSAGES = [
    {
      id: "massage_1",
      name: "Masaż relaksacyjny całego ciała",
      desc: "",
    },
    {
      id: "massage_2",
      name: "Masaż twarzy Kobido",
      desc: "2.pdf",
    },
    {
      id: "massage_3",
      name: "Hiszpański masaż twarzy (chiromasaż)",
      desc: "3.pdf",
    },
    {
      id: "massage_4",
      name: "Relaksacyjny (rozluźniający) masaż pleców",
      desc: "4.pdf",
    },
    {
      id: "massage_5",
      name: "Relaksacyjny (rozluźniający) masaż karku",
      desc: "5.pdf",
    },
    {
      id: "massage_6",
      name: "Relaksacyjny (rozluźniający) masaż karku + czworoboczny",
      desc: "6.pdf",
    },
    {
      id: "massage_7",
      name: "Relaksacyjny masaż twarzy",
      desc: "7.pdf",
    },
    {
      id: "massage_8",
      name: "Masaż twarzy z zabiegiem liftingującym „Szybka pomoc”",
      desc: "8.pdf",
    },
    {
      id: "massage_9",
      name: "Masaż twarzy z zabiegiem nawilżającym",
      desc: "9.pdf",
    },
    {
      id: "massage_10",
      name: "Masaż twarzy z zabiegiem wygladzania i wzmacniania skóry",
      desc: "10.pdf",
    },
    {
      id: "massage_11",
      name: "Relaksacyjny masaż stóp",
      desc: "11.pdf",
    },
    {
      id: "massage_12",
      name: "Relaksacyjny masaż stóp + kończyny dolne",
      desc: "12.pdf",
    },
    {
      id: "massage_13",
      name: "Masaż głowy i okolic głowy",
      desc: "13.pdf",
    },
    {
      id: "massage_14",
      name: "Masaż rąk",
      desc: "14.pdf",
    },
    {
      id: "massage_15",
      name: "Masaż rąk + parafina",
      desc: "15.pdf",
    },
    {
      id: "massage_16",
      name: "Banka chińska (rozluźniający bądź na cellulit)",
      desc: "16.pdf",
    },
  ];
  return (
    <div>
      <Header benefit = "#benefity"
            massageTypes = "#massage-types"
            contact = "#contact"
            pricelist = "/pricelist"></Header>
      <section id="tags-section" className="tags-section">
        <div className="tags-blurred"></div>
        <div className="tags">
          <div className="tags-bg-image"></div>
          <p className="tag-1">Bezpieczeństwo i higiena</p>
          <p className="tag-2">Pakiet zabiegów w atrakcyjnych cenach</p>
          <p className="tag-3">Programy lojalnościowe</p>
        </div>
      </section>

      <div id="massage-types" className="massage-types">
        <h3>RODZAJE WYKONYWANYCH MASAŻY</h3>
        <div className="massage">
          {MASSAGES.map((e) => {
            return <MassageContainer el={e}></MassageContainer>;
          })}
        </div>
      </div>

      <div id="contact" className="location-info">
        <h3>KONTAKT</h3>
        <div className="contact-info">
          <div className="phone">
            <Phone />
            <h4>+(48) 576-585-945</h4>
          </div>
          <div className="address">
            <Address />
            <h4>ul.Prusa 8C, lok. 12, 20-064 Lublin</h4>
          </div>
          <div className="facebook">
            <Facebook/>
            <a href="https://www.facebook.com/profile.php?id=61562143676522">Facebook</a>
          </div>
        </div>
        <div class="google-maps">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d510.0126089145393!2d22.563590300619104!3d51.253494507395146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472257440ebb2daf%3A0x82e000b5ecac8cde!2sCzas%20na%20relaks%2C%20Lublin!5e0!3m2!1spl!2spl!4v1721041604995!5m2!1spl!2spl"
            width="600"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
