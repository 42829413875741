// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.massage-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #857559 solid 2px;
    padding: 0px 5px;
    margin-top: 15px;
}

.massage-card a{
    font-size: 15px;
    font-weight: 700;
    color:#857559;
    text-align: center;
}

@media only screen and (max-width: 800px) {
    .massage-card a{
        width: 25%;
        font-size: 13px;
        text-align: end;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/containers/MassageContainer/MassageContainer.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gCAAgC;IAChC,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI;QACI,UAAU;QACV,eAAe;QACf,eAAe;IACnB;;AAEJ","sourcesContent":["\n.massage-card{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: #857559 solid 2px;\n    padding: 0px 5px;\n    margin-top: 15px;\n}\n\n.massage-card a{\n    font-size: 15px;\n    font-weight: 700;\n    color:#857559;\n    text-align: center;\n}\n\n@media only screen and (max-width: 800px) {\n    .massage-card a{\n        width: 25%;\n        font-size: 13px;\n        text-align: end;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
