// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header{
    
    border-bottom: #857559 solid 2px;
    margin-bottom: 25px;
}

.main-info{
    display: flex;
    padding: 20px 50px;
    align-items: center;
    justify-content: space-between;
    
    margin: 0 auto;
    
    width: 80%;
}

.main-info a{
    text-decoration: none;
    color: #857559;
    font-size: 20px;
    font-weight: 500;
    margin-left: 50px;
}

.headers{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.headers h1{
    font-size: 25px;
    border-bottom: #857559 solid 2px;
}

.headers h2{
    font-size: 20px;
    
}

@media only screen and (max-width: 800px) {
    .headers h1{
        font-size: 20px;
        border-bottom: #857559 solid 2px;
    }
    
    .headers h2{
        font-size: 15px;
        
    }

    .main-info{
        padding: 10px 25px;
    }

    #massage-type-header, #benefity{
        display: none;
    }
    
    .main-info a{
        font-size: 15px;
        margin-left: 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/containers/header/Header.css"],"names":[],"mappings":"AAAA;;IAEI,gCAAgC;IAChC,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,8BAA8B;;IAE9B,cAAc;;IAEd,UAAU;AACd;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,eAAe;;AAEnB;;AAEA;IACI;QACI,eAAe;QACf,gCAAgC;IACpC;;IAEA;QACI,eAAe;;IAEnB;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":["header{\n    \n    border-bottom: #857559 solid 2px;\n    margin-bottom: 25px;\n}\n\n.main-info{\n    display: flex;\n    padding: 20px 50px;\n    align-items: center;\n    justify-content: space-between;\n    \n    margin: 0 auto;\n    \n    width: 80%;\n}\n\n.main-info a{\n    text-decoration: none;\n    color: #857559;\n    font-size: 20px;\n    font-weight: 500;\n    margin-left: 50px;\n}\n\n.headers{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n\n.headers h1{\n    font-size: 25px;\n    border-bottom: #857559 solid 2px;\n}\n\n.headers h2{\n    font-size: 20px;\n    \n}\n\n@media only screen and (max-width: 800px) {\n    .headers h1{\n        font-size: 20px;\n        border-bottom: #857559 solid 2px;\n    }\n    \n    .headers h2{\n        font-size: 15px;\n        \n    }\n\n    .main-info{\n        padding: 10px 25px;\n    }\n\n    #massage-type-header, #benefity{\n        display: none;\n    }\n    \n    .main-info a{\n        font-size: 15px;\n        margin-left: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
