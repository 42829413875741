// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
h1,h2,h3,h4,h5,h6{
  text-align: center;
  color: #857559;
}

h1{
  font-size: 50px;
}

h2{
  font-size: 40px;
}

h3{
  font-size: 30px;
}

h4{
  font-size: 20px;
}

p{
  color: #857559;
}


@media only screen and (max-width: 800px) {
  h1{
    font-size: 36px;
  }
  
  h2{
    font-size: 29px;
  }
  
  h3{
    font-size: 22px;
  }
  
  h4{
    font-size: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;;AAGA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":["\nh1,h2,h3,h4,h5,h6{\n  text-align: center;\n  color: #857559;\n}\n\nh1{\n  font-size: 50px;\n}\n\nh2{\n  font-size: 40px;\n}\n\nh3{\n  font-size: 30px;\n}\n\nh4{\n  font-size: 20px;\n}\n\np{\n  color: #857559;\n}\n\n\n@media only screen and (max-width: 800px) {\n  h1{\n    font-size: 36px;\n  }\n  \n  h2{\n    font-size: 29px;\n  }\n  \n  h3{\n    font-size: 22px;\n  }\n  \n  h4{\n    font-size: 15px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
