import React from "react";

function MassagePriceTable(props) {
  return (
    <div className="table">
      <h4>{props.info.service}</h4>
      <table>
        <tr>
          <th>Czas</th>
          <th>Cena usługi</th>
        </tr>
        {props.info.pricing.map((el) => {
          return (
            <tr>
              <td>{el.time}</td>
              <td>{el.price}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default MassagePriceTable;
