import { Route, Routes } from "react-router-dom";
import "./App.css";
import MainPage from "./pages/MainPage/MainPage";
import PricelistPage from "./pages/PricelistPage/PricelistPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<MainPage></MainPage>}></Route>
        <Route path="/pricelist" element={<PricelistPage></PricelistPage>}></Route>
      </Routes>
    </div>
  );
}

export default App;
