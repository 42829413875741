import React from "react";
import Header from "../../components/containers/header/Header";
import MassagePriceTable from "../../components/elements/MassagePriceTable/MassagePriceTable";
import "./PricelistPage.css";

function Pricelist() {
  const massages = [
    {
      service: "Masaż relaksacyjny całego ciała (1,5 godz.)",
      pricing: [
        {
          time: "90 minut",
          price: "180 zł",
        },
        {
          time: "Seria zabiegów 5x90 minut",
          price: "810 zł",
        },
        {
          time: "Seria zabiegów 10x90 minut",
          price: "1530 zł",
        },
      ],
    },
    {
      service: "Masaż twarzy Kobido (1,5 godz.)",
      pricing: [
        {
          time: "90 minut",
          price: "200 zł",
        },
        {
          time: "Seria zabiegów 5x90 minut",
          price: "900 zł",
        },
        {
          time: "Seria zabiegów 10x90 minut",
          price: "1700 zł",
        },
      ],
    },
    {
      service: "Hiszpański masaż twarzy (chiromasaż) (1 godz.)",
      pricing: [
        {
          time: "60 minut",
          price: "120 zł",
        },
        {
          time: "Seria zabiegów 5x60 minut",
          price: "540 zł",
        },
        {
          time: "Seria zabiegów 10x60 minut",
          price: "1020 zł",
        },
      ],
    },
    {
      service: "Mięśniowo-powięziowy masaż twarzy (1 godz. 30 min.)",
      pricing: [
        {
          time: "90 minut",
          price: "220 zł",
        },
        {
          time: "Seria zabiegów 5x90 minut",
          price: "990 zł",
        },
        {
          time: "Seria zabiegów 10x90 minut",
          price: "1870 zł",
        },
      ],
    },
    {
      service: 'Masaż twarzy "Konturowanie Twarzy" (autorskie) (45 min.)',
      pricing: [
        {
          time: "45 minut",
          price: "100 zł",
        },
        {
          time: "Seria zabiegów 5x45 minut",
          price: "450 zł",
        },
        {
          time: "Seria zabiegów 10x45 minut",
          price: "850 zł",
        },
      ],
    },
    {
      service: "Relaksacyjny (rozluźniający) masaż pleców (30 min/1 godz.)",
      pricing: [
        {
          time: "30 minut",
          price: "60 zł",
        },
        {
          time: "Seria zabiegów 5x30 minut",
          price: "270 zł",
        },
        {
          time: "Seria zabiegów 10x30 minut",
          price: "510 zł",
        },
        {
          time: "60 minut",
          price: "120 zł",
        },
        {
          time: "Seria zabiegów 5x60 minut",
          price: "540 zł",
        },
        {
          time: "Seria zabiegów 10x60 minut",
          price: "1020 zł",
        },
      ],
    },
    {
      service: "Relaksacyjny (rozluźniający) masaż karku (30 min.)",
      pricing: [
        {
          time: "30 minut",
          price: "60 zł",
        },
        {
          time: "Seria zabiegów 5x30 minut",
          price: "270 zł",
        },
        {
          time: "Seria zabiegów 10x30 minut",
          price: "510 zł",
        },
      ],
    },
    {
      service:
        "Relaksacyjny (rozluźniający) masaż karku + czworoboczny (45 min.)",
      pricing: [
        {
          time: "45 minut",
          price: "90 zł",
        },
        {
          time: "Seria zabiegów 5x45 minut",
          price: "405 zł",
        },
        {
          time: "Seria zabiegów 10x45 minut",
          price: "765 zł",
        },
      ],
    },
    {
      service: "Relaksacyjny masaż twarzy (30 min.)",
      pricing: [
        {
          time: "30 minut",
          price: "60 zł",
        },
        {
          time: "Seria zabiegów 5x30 minut",
          price: "270 zł",
        },
        {
          time: "Seria zabiegów 10x30 minut",
          price: "510 zł",
        },
      ],
    },
    {
      service:
        "Masaż twarzy z zabiegiem liftingującym „Szybka pomoc” (1 godz.)",
      pricing: [
        {
          time: "60 minut",
          price: "150 zł",
        },
        {
          time: "Seria zabiegów 5x60 minut",
          price: "675 zł",
        },
      ],
    },
    {
      service: "Masaż twarzy z zabiegiem nawilżającym (50 min.)",
      pricing: [
        {
          time: "50 minut",
          price: "110 zł",
        },
        {
          time: "Seria zabiegów 5x50 minut",
          price: "495 zł",
        },
        {
          time: "Seria zabiegów 10x50 minut",
          price: "935 zł",
        },
      ],
    },
    {
      service:
        "Masaż twarzy z zabiegiem wygładzania i wzmacniania skóry (1 godz. 10 min)",
      pricing: [
        {
          time: "70 minut",
          price: "130 zł",
        },
        {
          time: "Seria zabiegów 5x70 minut",
          price: "585 zł",
        },
        {
          time: "Seria zabiegów 10x70 minut",
          price: "1105 zł",
        },
      ],
    },
    {
      service: "Maseczka nawilżająca na twarz (20 min.)",
      pricing: [
        {
          time: "20 minut",
          price: "30 zł",
        },
      ],
    },
    {
      service: "Relaksacyjny masaż stóp (30 min.)",
      pricing: [
        {
          time: "30 minut",
          price: "60 zł",
        },
        {
          time: "Seria zabiegów 5x30 minut",
          price: "270 zł",
        },
        {
          time: "Seria zabiegów 10x30 minut",
          price: "510 zł",
        },
      ],
    },
    {
      service: "Relaksacyjny masaż stóp + kończyny dolne (1 godz.)",
      pricing: [
        {
          time: "60 minut",
          price: "120 zł",
        },
        {
          time: "Seria zabiegów 5x60 minut",
          price: "540 zł",
        },
        {
          time: "Seria zabiegów 10x60 minut",
          price: "1020 zł",
        },
      ],
    },
    {
      service: "Masaż głowy, dekolt, szyja (30 min.)",
      pricing: [
        {
          time: "30 minut",
          price: "60 zł",
        },
        {
          time: "Seria zabiegów 5x30 minut",
          price: "270 zł",
        },
        {
          time: "Seria zabiegów 10x30 minut",
          price: "510 zł",
        },
      ],
    },
    {
      service: "Masaż rąk (20 min./ 30 min.)",
      pricing: [
        {
          time: "20 minut",
          price: "45 zł",
        },
        {
          time: "Seria zabiegów 5x20 minut",
          price: "200 zł",
        },
        {
          time: "Seria zabiegów 10x20 minut",
          price: "380 zł",
        },
        {
          time: "30 minut",
          price: "60 zł",
        },
        {
          time: "Seria zabiegów 5x30 minut",
          price: "270 zł",
        },
        {
          time: "Seria zabiegów 10x30 minut",
          price: "510 zł",
        },
      ],
    },
    {
      service: "Masaż rąk + parafina (50 min.)",
      pricing: [
        {
          time: "50 minut",
          price: "100 zł",
        },
        {
          time: "Seria zabiegów 5x50 minut",
          price: "450 zł",
        },
        {
          time: "Seria zabiegów 10x50 minut",
          price: "850 zł",
        },
      ],
    },
    {
      service: "Masaż antycellulitowy + bańka chińska (30 min/1 godz.)",
      pricing: [
        {
          time: "30 minut",
          price: "100 zł",
        },
        {
          time: "Seria zabiegów 5x30 minut",
          price: "450 zł",
        },
        {
          time: "Seria zabiegów 10x30 minut",
          price: "850 zł",
        },
        {
          time: "60 minut",
          price: "200 zł",
        },
        {
          time: "Seria zabiegów 5x60 minut",
          price: "900 zł",
        },
        {
          time: "Seria zabiegów 10x60 minut",
          price: "1700 zł",
        },
      ],
    },
  ];

  return (
    <div>
      <Header
        benefit="/"
        massageTypes="/"
        contact="/"
        pricelist="#tables"
      ></Header>
      <div id="tables">
        {massages.map((el) => (
          <MassagePriceTable info={el}></MassagePriceTable>
        ))}
      </div>
    </div>
  );
}

export default Pricelist;
