import React from 'react'

import "./Header.css";

function Header(props) {
  return (
    <header>
        <div className="main-info">
          <div className="headers">
            <h1>Czas na Relaks</h1>
            <h2>Gabinet masażu</h2>
          </div>

          <nav>
            <a id="benefity" href={props.benefit}>Benefity</a>
            <a id="massage-type-header" href={props.massageTypes}>Rodzaje masaży</a>
            <a  href={props.contact}>Kontakt</a>
            <a  href={props.pricelist}>Cennik</a>
          </nav>
        </div>
      </header>
  )
}

export default Header